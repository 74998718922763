
import "@/assets/sass/template.scss";
import { defineComponent, onMounted, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import TableTab from "@/components/kt-datatable/TableTab.vue";
import moment from "moment";
import PaginationUtill from "../util/PaginationUtill";
import store from "@/store";
export default defineComponent({
  name: "DataTable",

  components: {
    TableTab,
  },

  setup() {
    let AccountId = store.getters.AccountId;
    const tableDatanew = ref([]);
    const graphHeader = ref([]);
    const colnames = ref();
    const graph = ref(false);
    const tablesumm = ref(false);
    const fromDate = ref(new Date());
    const toDate = ref(new Date());
    const loading = ref<boolean>(false);
    const series = reactive([{}]);
    const pageheader = "Last 'Actual' Meter Read Report - Gas";
    interface TableHeader {
      name: string;
      key: string;
      sortable: boolean;
    }

    onMounted(() => {
      setCurrentPageBreadcrumbs("Last 'Actual' Meter Read Report - Gas", [
        "NA",
      ]);
      graph.value = true;
      tablesumm.value = false;
    });
    const OriginalTableHeaders = ref<Array<TableHeader>>();
    let tabheader: Array<TableHeader> = PaginationUtill.LastActualMeterReadGas;
    OriginalTableHeaders.value = tabheader.map((a) => ({
      name: a.name,
      key: a.key,
      sortable: true,
    }));

    async function getContent() {
      graph.value = true;
      loading.value = true;
      let fdate: any = new Date();
      fdate.setDate(fdate.getDate() - 364);
      let tdate: any = new Date();
      tdate.setDate(tdate.getDate());
      //let tdate: any = new Date().toLocaleDateString();

      const accountInfo: any = {
        accountid: AccountId,
        fdate: moment(fdate).format("YYYY-MM-DD 00:00:00"),
        tdate: moment(tdate).format("YYYY-MM-DD 00:00:00"),
      };

      ApiService.post("api/lastactualmeterread/gas_actualmeter", accountInfo)
        .then((resp) => {
          tableDatanew.value = resp.data.lastActualmreadGas.map((a) => a);
          colnames.value = tabheader.map((a) => a.key);
          graphHeader.value = resp.data.lastActualmreadGasGraphdata.map(
            (a) => a.values
          );

          let dataval = resp.data.lastActualmreadGasGraphdata.map((a) => ({
            x: a.values,
            y: a.plotvalues,
          }));
          loading.value = false;
          series.splice(0);
          const datas = {
            // data: dataval[0] === undefined ? [] : dataval,
            data: dataval,
          };
          series.push(datas);
        })
        .catch((a) => {
          loading.value = false;
          console.log("Api Log Error", a);
        });
    }

    function openAssetGraph() {
      graph.value = true;
      tablesumm.value = false;
    }

    function openAssetRemoval() {
      graph.value = false;
      tablesumm.value = true;
    }

    const ebchartType = ref("bar");
    const gaschartType = ref("bar");
    const eboptions = {
      chartOptions: {
        chart: {
          type: "bar",

          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 1,
            columnWidth: 25 + "%",
          },
        },
        colors: [
          "#F3B415",
          "#F27036",
          "#663F59",
          "#6A6E94",
          "#4E88B4",
          "#00A7C6",
          "#18D8D8",
          "#A9D794",
          "#46AF78",
          "#A93F55",
          "#8C5E58",
          "#2176FF",
          "#33A1FD",
          "#7A918D",
          "#BAFF29",
        ],
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          title: {
            text: "Number of Locations",
            style: {
              fontSize: "12px",
            },
          },
        },
        legend: {
          show: true,
          floating: false,
          fontSize: "20px",
        },
      },
    };

    function changeChartType(type) {
      ebchartType.value = type;
    }

    function changeGasChartType(type) {
      gaschartType.value = type;
    }

    getContent();

    return {
      OriginalTableHeaders,
      pageheader,
      getContent,
      fromDate,
      toDate,
      openAssetGraph,
      openAssetRemoval,
      graph,
      tablesumm,
      eboptions,
      gaschartType,
      ebchartType,
      changeChartType,
      changeGasChartType,
      loading,
      tabheader,
      tableDatanew,
      colnames,
      series,
      graphHeader,
    };
  },
});
